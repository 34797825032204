<template>
  <div class="home">
    <header>
      <nav>
        <h1 style="color:red">Validation</h1>
        <div>Se ha verificado el usuario : {{ $route.params.id }}</div>
      </nav>
    </header>
  </div>
</template>

<script>
import { imageMix } from "@/common/mixin.js";
import axios from "axios";

export default {
  name: "Validation",
  mixins: [imageMix],
  data: function() {
    return {};
  },
  methods: {
    async onValidateUser() {
      const userKey = this.$route.params.id || null;

    },
    async onValidateUser() {

      await apiClient
        .post("/validation", body)
        .then(response => {
          const { user } = response.data;
          if (user) {
            localStorage.setItem("user", user.nick);
            this.$store.commit("setUser", this.parseImg(user));
            this.$router.push("/");
          } else {
            this.$router.push("/");
          }
        })
    }
  },
  mounted() {
    this.onValidateUser();
  }
};
</script>
